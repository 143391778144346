<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-90">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Escolha um modelo</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body block-el p-0">
            <div class="row">
              <div class="col-sm-2">
                <select class="form-control" @change="fetchType($event)">
                  <option value="" selected>Tipo</option>
                  <option value="Assinatura">Assinatura</option>
                  <option value="Acompanhamento">Acompanhamento</option>
                  <option value="Alerta de evento">Alerta de evento</option>
                  <option value="Atualização de Produto">Atualização de Produto</option>
                  <option value="Acionar">Acionar</option>
                  <option value="Alertas e Notificações">Alertas e Notificações</option>
                  <option value="Bem vindo(a)">Bem vindo(a)</option>
                  <option value="Confirmação">Confirmação</option>
                  <option value="Carrinho abandonado">Carrinho abandonado</option>
                  <option value="Convite">Convite</option>
                  <option value="Confirmação">Confirmação</option>
                  <option value="E-mails de indicação">E-mails de indicação</option>
                  <option value="E-mails Frios">E-mails Frios</option>
                  <option value="Interativo">Interativo</option>
                  <option value="Interno">Interno</option>
                  <option value="Nova Coleção">Nova Coleção</option>
                  <option value="Desconto">Desconto</option>
                  <option value="Obrigado(a)">Obrigado(a)</option>
                  <option value="Pedido">Pedido</option>
                  <option value="Pedido de desculpas">Pedido de desculpas</option>
                  <option value="Pesquisa e Feedback">Pesquisa e Feedback</option>
                  <option value="Pós-venda">Pós-venda</option>
                  <option value="Promoção">Promoção</option>
                  <option value="Redefinição de senha">Redefinição de senha</option>
                  <option value="Resumo de E-mail">Resumo de E-mail</option>
                  <option value="Retargeting">Retargeting</option>
                  <option value="Retenção e Reativação">Retenção e Reativação</option>
                  <option value="Eventos">Eventos</option>
                  <option value="Testes">Testes</option>
                  <option value="Transacional">Transacional</option>
                  <option value="Ecommerce">Ecommerce</option>
                  <option value="Festas">Festas</option>
                  <option value="Newsletter">Newsletter</option>
                  <option value="Promoções">Promoções</option>
                  <option value="Vendas">Vendas</option>
                  <option value="Outros">Outros</option>
                </select>
              </div>
              <div class="col-sm-2">
                <select class="form-control" @change="fetchStation($event)">
                  <option value="" selected>Estação</option>
                  <option value="Aniversário">Aniversário</option>
                  <option value="Ação de Graças">Ação de Graças</option>
                  <option value="Ano Novo">Ano Novo</option>
                  <option value="Ano Novo Chinês">Ano Novo Chinês</option>
                  <option value="Cinco de Mayo">Cinco de Mayo</option>
                  <option value="Convite de casamento">Convite de casamento</option>
                  <option value="Cyber Monday">Cyber Monday</option>
                  <option value="Dia ANZAC">Dia ANZAC</option>
                  <option value="Dia da Austrália">Dia da Austrália</option>
                  <option value="Dia da Bastilha">Dia da Bastilha</option>
                  <option value="Dia da Igualdade Feminina">Dia da Igualdade Feminina</option>
                  <option value="Dia da Independência">Dia da Independência</option>
                  <option value="Dia da Mentira">Dia da Mentira</option>
                  <option value="Dia da Unidade Alemã">Dia da Unidade Alemã</option>
                  <option value="Dia de São Patrício">Dia de São Patrício</option>
                  <option value="Dia do Buda">Dia do Buda</option>
                  <option value="Dia do Presidente">Dia do Presidente</option>
                  <option value="Dia do videogame">Dia do videogame</option>
                  <option value="Dia dos Amantes do Livro">Dia dos Amantes do Livro</option>
                  <option value="Dia dos Mortos">Dia dos Mortos</option>
                  <option value="Dia dos Namorados">Dia dos Namorados</option>
                  <option value="Dia Internacional da Enfermeira">Dia Internacional da Enfermeira</option>
                  <option value="Dia Internacional da Mulher">Dia Internacional da Mulher</option>
                  <option value="Dia Internacional das Famílias">Dia Internacional das Famílias</option>
                  <option value="Dia Internacional do Chocolate">Dia Internacional do Chocolate</option>
                  <option value="Dia internacional do programador">Dia internacional do programador</option>
                  <option value="Dia Internacional dos Homens">Dia Internacional dos Homens</option>
                  <option value="Dia Mundial do Meio Ambiente">Dia Mundial do Meio Ambiente</option>
                  <option value="Dia Mundial do Professor">Dia Mundial do Professor</option>
                  <option value="Dia Mundial do Turismo">Dia Mundial do Turismo</option>
                  <option value="Dia Mundial Vegano">Dia Mundial Vegano</option>
                  <option value="Diwali">Diwali</option>
                  <option value="Ferragosto">Ferragosto</option>
                  <option value="Festa do Sacrifício">Festa do Sacrifício</option>
                  <option value="Festival Global de Compras">Festival Global de Compras</option>
                  <option value="Hanukkah">Hanukkah</option>
                  <option value="Hora Terrestre">Hora Terrestre</option>
                  <option value="Horário de verão">Horário de verão</option>
                  <option value="Mardi Gras">Mardi Gras</option>
                  <option value="Memorial Day">Memorial Day</option>
                  <option value="Mês da História Negra">Mês da História Negra</option>
                  <option value="Mês de Conscientização ao Câncer de Mama">Mês de Conscientização ao Câncer de Mama</option>
                  <option value="Mês do Orgulho LGBTQ">Mês do Orgulho LGBTQ</option>
                  <option value="Octoberfest">Octoberfest</option>
                  <option value="Primeiro dia do outono">Primeiro dia do outono</option>
                  <option value="Purim">Purim</option>
                  <option value="Segunda-feira de carnaval">Segunda-feira de carnaval</option>
                  <option value="Semana da moda">Semana da moda</option>
                  <option value="Sexta-feira 13">Sexta-feira 13</option>
                  <option value="Small Business Saturday">Small Business Saturday</option>
                  <option value="Super Bowl">Super Bowl</option>
                  <option value="Terça solidária">Terça solidária</option>
                  <option value="Volta às Aulas">Volta às Aulas</option>
                  <option value="Waitangi Day">Waitangi Day</option>
                  <option value="Black Friday">Black Friday</option>
                  <option value="Dia das mães">Dia das mães</option>
                  <option value="Dia da Terra">Dia da Terra</option>
                  <option value="Dia do Trabalho">Dia do Trabalho</option>
                  <option value="Dia dos pais">Dia dos pais</option>
                  <option value="Moda">Moda</option>
                  <option value="Natal">Natal</option>
                  <option value="Páscoa">Páscoa</option>
                  <option value="Primavera">Primavera</option>
                  <option value="Halloween">Halloween</option>
                  <option value="Inverno">Inverno</option>
                  <option value="Outros">Outros</option>
                </select>
              </div>
              <div class="col-sm-2">
                <select class="form-control" @change="fetchResource($event)">
                  <option value="" selected>Recurso</option>
                  <option value="AMP">AMP</option>
                  <option value="Animação CSS">Animação CSS</option>
                  <option value="Carousel">Carousel</option>
                  <option value="Cronômetro decrescente">Cronômetro decrescente</option>
                  <option value="Elementos inteligentes">Elementos inteligentes</option>
                  <option value="Ludificação">Ludificação</option>
                  <option value="Menu Sanfona">Vídeo</option>
                  <option value="Rolagem de Imagens">Vídeo</option>
                  <option value="Tipografia">Tipografia</option>
                  <option value="Carousel">Carousel</option>
                  <option value="Outros">Outros</option>
                </select>
              </div>
              <div class="col-sm-2">
                <select class="form-control" @change="fetchIndustry($event)">
                  <option value="" selected>Indústria</option>
                  <option value="Arte">Arte</option>
                  <option value="Animais de Estimação">Animais de Estimação</option>
                  <option value="Beleza e Cuidados Pessoais">Beleza e Cuidados Pessoais</option>
                  <option value="Caridade">Caridade</option>
                  <option value="Casa e Jardim">Casa e Jardim</option>
                  <option value="Cinema e Música">Cinema e Música</option>
                  <option value="Construção">Construção</option>
                  <option value="Consultoria">Consultoria</option>
                  <option value="Cuidados de saúde">Cuidados de saúde</option>
                  <option value="Design">Design</option>
                  <option value="Educação">Educação</option>
                  <option value="Eletrônicos">Eletrônicos</option>
                  <option value="Entretenimento">Entretenimento</option>
                  <option value="Esporte e Preparo físico">Esporte e Preparo físico</option>
                  <option value="Finanças">Finanças</option>
                  <option value="Fotografia">Fotografia</option>
                  <option value="Gadgets">Gadgets</option>
                  <option value="Hobbies">Hobbies</option>
                  <option value="Hotéis">Hotéis</option>
                  <option value="Imóveis">Imóveis</option>
                  <option value="Jogos">Jogos</option>
                  <option value="Joias">Joias</option>
                  <option value="Jurídico">Jurídico</option>
                  <option value="Linhas Aéreas">Linhas Aéreas</option>
                  <option value="Livros, Presentes e Papelaria">Livros, Presentes e Papelaria</option>
                  <option value="Moda">Moda</option>
                  <option value="Logística e Entrega">Logística e Entrega</option>
                  <option value="Móveis, Interior e Faça-Você-Mesmo">Móveis, Interior e Faça-Você-Mesmo</option>
                  <option value="Negócios">Negócios</option>
                  <option value="Presentes e Artesanatos">Presentes e Artesanatos</option>
                  <option value="Produtos Infantis">Produtos Infantis</option>
                  <option value="Produtos Orgânicos e Ecológicos">Produtos Orgânicos e Ecológicos</option>
                  <option value="Publicações e Blogging">Publicações e Blogging</option>
                  <option value="Restaurantes">Restaurantes</option>
                  <option value="Saúde e bem-estar">Saúde e bem-estar</option>
                  <option value="Seguros">Seguros</option>
                  <option value="Sem fins lucrativos">Sem fins lucrativos</option>
                  <option value="Webinars">Webinars</option>
                  <option value="Auto e Moto">Auto e Moto</option>
                  <option value="Software e Tecnologia">Software e Tecnologia</option>
                  <option value="Bebida">Bebidas</option>
                  <option value="Comida">Comida</option>
                  <option value="Viagem e Turismo">Viagem e Turismo</option>
                  <option value="Outros">Outros</option>
                </select>
              </div>
              <div class="col-sm-4">
                <input type="text" class="form-control" placeholder="Pesquisar" @keyup="searchLib">
              </div>
              <div class="col-md-12">
                <hr>
              </div>
            </div>
            <div class="row" v-if="fetched">
              <div v-for="(template, i) in templates" :key="template.id" class="slate col-sm-4 col-md-3 col-lg-2 esdev-mb15 preview-template-wrapper ng-star-inserted">
                <div class="template-content">
                  <a
                    class="template-image cursor-pointer"
                    v-tooltip.top="template.name"
                    :id="'template-id-' + i"
                    @click="openEdit(template)"
                  >
                    <img v-if="template.preview_url" class="img-responsive ng-star-inserted" :src="`${template.preview_url}`">
                    <img v-else class="img-responsive ng-star-inserted" src="/assets/img/empty.png">
                  </a>
                  <div class="one-info">
                    <div class="template-name">
                      {{template.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="responsive-table">
              <div
                v-if="!templates.length && fetched"
                class="text-center table-placeholder"
              >
                <i
                  class="fas fa-envelope font-size-80"
                  v-tooltip.top="
                    $t('template-component.tooltip')
                  "
                ></i>
                <h5 class="card-title m-t-20">{{$t('sms.templates.none')}}</h5>
                <p>{{$t('sms.templates.lbl-register')}}</p>
              </div>
              <div
                v-if="!fetched"
                class="qt-block-ui relative"
                style="padding: 120px; margin: 0;"
              />
              <br />
              <pagination :lastPage="pages" @change="fetch" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hide">
            {{$t('generic-str.cancel')}}
          </button>
          <button
            @click="createPage()"
            class="btn btn-success"
          >
            Criar modelo em branco
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
import Modal from '@/mixins/Modal';
import Pagination from '@/components/Pagination.vue';

export default {
  components: {
    Pagination,
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      isSending: false,
      category: 0,
      formFilter: {
        page: 1,
      },
      fetched: true,
      templates: [],
      pages: 1,
    };
  },
  mounted() {
    console.log(this.$store.state.account.id);
    this.fetch(1);
  },
  methods: {
    createPage() {
      this.$router.push('/mail/templates/create');
    },
    openEdit(item) {
      if (this.data.isLib) {
        this.$root.$emit('template.changeModel', item);
        this.hide();
      } else {
        this.$router.push(`/mail/templates/create/${item.id}/true`);
      }
    },
    save() {
      this.isSending = true;
    },
    fetch(page) {
      this.fetched = false;
      MailService.getLibs({
      }, page).then(
        (response) => {
          this.fetched = true;
          this.templates = response.data;
          this.pages = response.last_page;
          console.log(response);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    filter() {
      MailService.getLibs(this.formFilter, this.form.page).then(
        (response) => {
          this.fetched = true;
          this.templates = response.data;
          this.pages = response.last_page;
          console.log(response);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    searchLib(event) {
      if (event.target.value) {
        this.formFilter.name = `lk:${event.target.value}`;
      } else {
        this.formFilter.name = undefined;
      }
      this.filter();
    },
    fetchType(event) {
      this.fetched = false;
      if (event.target.value) {
        this.formFilter.type = event.target.value;
      } else {
        this.formFilter.type = undefined;
      }
      this.filter();
    },
    fetchStation(event) {
      this.fetched = false;
      if (event.target.value) {
        this.formFilter.station = event.target.value;
      } else {
        this.formFilter.station = undefined;
      }
      this.filter();
    },
    fetchResource(event) {
      this.fetched = false;
      if (event.target.value) {
        this.formFilter.resource = event.target.value;
      } else {
        this.formFilter.resource = undefined;
      }
      this.filter();
    },
    fetchIndustry(event) {
      this.fetched = false;
      if (event.target.value) {
        this.formFilter.industry = event.target.value;
      } else {
        this.formFilter.industry = undefined;
      }
      this.filter();
    },
  },
};
</script>

<style scoped lang="scss">
.template-image {
  height: 0;
  padding-bottom: 75%;
  display: inline-block;
  overflow: hidden;
  z-index: 0;
  position: relative;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 0 1px #ddd;
  width: 100%;
  background: #f5f5f5;
  vertical-align: top;
  margin-bottom: -5px;
  z-index: 0;
}
.one-info{
  box-shadow: 0 0 0 1px #ddd;
  border-radius: 0 0 10px 10px;
  padding: 12px 10px 0;
  background-color: #fff;
}
.template-image img{
  border-radius: 10px 10px 10px 10px;
}
.template-image:hover{
  overflow: visible;
  z-index: 1;
}

.template-content img:hover{
  border: solid 2px #635ebe;
}
.template-content{
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 30px;
}
.cursor-pointer {
  cursor: pointer;
}

.template-name{
  margin-top: 0px;
}
.template-name {
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
  display: inline-block;
  width: 95%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.modal-90{
  max-width: 90% !important;
}
</style>
